import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"

const InfoPage = () => {
  return (
    <Layout>
      <div>Info für User ohne ausreichende Berechtigung</div>
    </Layout>
  )
}

export default InfoPage
